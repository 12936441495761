.recipes-page .accordion .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recipes-page .accordion .title:not(.ui) {
  font-size: 16px;
}

.recipes-page .accordion .title .dropdown.icon {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}

.recipes-page .accordion-content {
  display: flex;
}

.recipes-page .accordion-content .recipes-media {
  width: 100%;
  max-width: 300px;
}

.recipes-page .accordion-content .recipes-content {
  margin-left: 20px;
}

.recipes-images-expand {
  float: right;
  cursor: pointer;
}
