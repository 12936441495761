html, body {
    height: 100%!important;
    width: 100%!important;
    margin: 0!important;
    padding: 0!important;
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
}

@media print {
    body, html, #App {
        width: 100%!important;
    }
}

/* Fonts **/

@font-face {
    font-family: 'NeutraBook';
    src: url("../font/Neutra-Book.eot");
    src: url("../font/Neutra-Book.eot?#iefix") format("embedded-opentype"), url("../font/Neutra-Book.woff") format("woff"), url("../font/Neutra-Book.ttf") format("truetype"), url("../font/Neutra-Book.svg#NeutraBook") format("svg");
    font-weight: normal !important;
    font-style: normal;
}

@font-face {
    font-family: 'NeutraBold';
    src: url("../font/Neutra-Bold.eot");
    src: url("../font/Neutra-Bold.eot?#iefix") format("embedded-opentype"), url("../font/Neutra-Bold.woff") format("woff"), url("../font/Neutra-Bold.ttf") format("truetype"), url("../font/Neutra-Bold.svg#NeutraBold") format("svg");
    font-weight: normal !important;
    font-style: normal;
}

h1 {
    font-family: "NeutraBook", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1.7rem;
}

a {
    color: #bbb;
    text-decoration: none !important;
}

a:hover {
    color: #888;
    text-decoration: underline;
}

#App {
    height: 100%;
}

input:focus {
    outline: none !important;
    border:1px solid #f47321!important;
    box-shadow: none!important;
}
textArea:focus {
    outline: none !important;
    border:1px solid #f47321!important;
    box-shadow: none!important;
}


/* Header */

.header-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    text-align: center;
    background: #f47321;
    z-index: 1900!important;
}

.content-main {
    height: 100%;
    width: 100%;
    overflow: auto;
    max-width: 100%;
    transform: none !important;
    float: right;
}

.content-main.menu-active{
    max-width: 75%;
}

.content-inner {
    margin-top: 50px;
    padding: 20px;
}

.navbar-brand {
    display: block;
    height: 30px;
    width: 63px;
    margin: 11px auto 0 auto;
    border: 0;
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    overflow: hidden;
    outline: none;
    text-indent: 100%;
    white-space: nowrap;
    background-image: url('../img/sprites/ui-sf4935c749f.png');
    background-position: 0 -215px;
    background-repeat: no-repeat;
    background-color: transparent;
}
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 3 / 2), (min-device-pixel-ratio: 2) {
    .navbar-brand {
        background-image: url('../img/sprites/ui-2x-s3bc90e0710.png');
        background-size: 201.5px 269.5px;
        background-position: 0 -207px;
        height: 30px;
        width: 63px;
    }
}


/* Breadcrumb */

.ui.big.breadcrumb {
    margin-top: 12px;
    margin-left: 170px;
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
}

.ui.breadcrumb a {
    color: #763106;
}

.ui.breadcrumb a:hover {
    color: #fff;
}

.ui.breadcrumb .active.section {
    color: #fff;
    font-weight: normal;
}

.ui.breadcrumb .divider {
    color: #763106;
}


/* Buttons **/

.ui.ui.ui.ui.large.button {
    /*font-size: 1rem;*/
    font-family: "NeutraBold", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.ui.button.large.fluid.light-grey {
    text-transform: uppercase;
    color: #888;
    background-color: #eee;
    font-weight: normal;
    padding: 1em;
    margin-top: 3rem;
}

.ui.button.large.fluid.light-grey:hover {
    background-color: #dfdfdf;
}

.modal .header {
    background-color: rgba(197, 197, 197, 0.78) !important;
    padding-top: 5px!important;
    padding-bottom: 5px!important;
}